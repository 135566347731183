import { useEffect, useState } from 'react';
import Link from 'next/link';
import 'tippy.js/dist/tippy.css';
import { Config } from '../../utils/config';
import {
  useContract,
  useValidDirectListings,
  useAddress,
  Status,
  useNetworkMismatch,
  useSwitchChain
} from '@thirdweb-dev/react';
import {
  marketplaceContractAddresses,
  networkToUse
} from '../contractAddress';
import swal from 'sweetalert';
import { SdkService } from '../../utils/sdk_service';
import { getProperChain } from '../../utils/helpers';

const CategoryItems = () => {
  const [buyingLoading, setBuyingLoading] = useState(false);
  const [data, setData] = useState(null);

  const marketplaceContractAddress = marketplaceContractAddresses[networkToUse];

  const {
    contract,
    isLoading: contractLoading,
    error: contractError
  } = useContract(marketplaceContractAddress);

  const {
    data: listingData,
    isLoading: listingLoading,
    error: listingError
  } = useValidDirectListings(contract, { start: 0, count: 10 });

  const address = useAddress();
  const isNetworkMismatch = useNetworkMismatch();
  const switchChain = useSwitchChain();
  const activeChain = getProperChain();

  const buy = async (id) => {
    setBuyingLoading(true);

    try {
      await SdkService.buyNow(id)
    } catch (e) {
      console.log('Something went wrong: ', e);
    } finally {
      setBuyingLoading(false);
    }
  };

  useEffect(() => {
    setData(listingData);
    if (!address) {
      swal(Config.SWAL_MESSAGES.NO_WALLET.TITLE, Config.SWAL_MESSAGES.NO_WALLET.DESCRIPTION, 'info');
    }
    if (isNetworkMismatch) {
      swal(Config.SWAL_MESSAGES.WRONG_NETWORK.TITLE, Config.SWAL_MESSAGES.WRONG_NETWORK.DESCRIPTION, 'warning');
      switchChain(activeChain?.chainId); // This is we it's making us to switch to Mainnet
    }
  }, [isNetworkMismatch, address]);

  useEffect(() => {
    if ( listingData) {
      setData(listingData);
    }
    if (listingError) {
      setData([]);
      if (!address) {
        swal(Config.SWAL_MESSAGES.NO_WALLET.TITLE, Config.SWAL_MESSAGES.NO_WALLET.DESCRIPTION, 'info');
      }
      if (isNetworkMismatch) {
        swal(Config.SWAL_MESSAGES.WRONG_NETWORK.TITLE, Config.SWAL_MESSAGES.WRONG_NETWORK.DESCRIPTION, 'warning');
      }
    }
  }, [listingData, listingError]);

  return (
    <div>
      <div className='ml-6 grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4'>
        {data?.map((listing) => {
          if (listing.status !== Status.Active) return null;
          const id = listing.id;
          const image = listing.asset.image;
          const title = listing.asset.name;
          const price = listing.currencyValuePerToken.displayValue;

          return (
            <article key={id}>
              <div className='dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg'>
                <Link
                  href={{
                    pathname: `/list/listing/${id}`
                  }}
                >
                  <div>
                    <figure className='relative'>
                      <img
                        src={image}
                        alt='item 5'
                        className='w-full h-[230px] rounded-[0.625rem] object-cover'
                      />

                      <div className='absolute left-3 -bottom-3'>
                        <div className='flex -space-x-2'></div>
                      </div>
                    </figure>
                    <div className='mt-7 flex items-center justify-between'>
                      {/* <Link href={`/item/${itemLink}`}> */}
                      <a>
                        <span className='font-display text-jacarta-700 hover:text-accent text-base dark:text-white'>
                          {title}
                        </span>
                      </a>
                      {/* </Link> */}

                      {/* auction dropdown  */}
                    </div>
                    {price ? (
                        <div className='mt-2 text-sm'>
                          <span className='dark:text-jacarta-200 text-jacarta-700 mr-1'>
                            {price} ETH
                          </span>
                        </div>
                    ) : null}
                  </div>
                </Link>

                {buyingLoading ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='w-6 h-6 animate-spin'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'
                    />
                  </svg>
                ) : (
                  <div className='mt-8 flex items-center justify-between'>
                    <button
                      className='text-accent font-display text-sm font-semibold'
                      onClick={() => buy(id)}
                      disabled={!address || isNetworkMismatch}
                    >
                      Buy now
                    </button>
                  </div>
                )}
              </div>
            </article>
          );
        })}
      </div>
    </div>
  );
};

export default CategoryItems;
